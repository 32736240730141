import axios from '@axios'

export default function coursesServices() {
  const getCourses = async (queryParams) => {
    const res = await axios.get('/courses', queryParams)
    return res
  }

  const getCourse = async (queryParams) => {
    const res = await axios.get('/course', {
      params: queryParams,
    })
    return res
  }

  const getQuestions = async (queryParams) => {
    const res = await axios.get('/questions', {
      params: queryParams,
    })
    return res
  }

  const postQuestion = async (queryParams) => {
    const res = await axios.post('/question/create', queryParams)
    console.log(res)
    return res
  }

  const getNotes = async (queryParams) => {
    const res = await axios.get('/notes', {
      params: queryParams,
    })
    return res
  }

  const postNotes = async (queryParams) => {
    const res = await axios.post('/note/create', queryParams)
    console.log(res)
    return res
  }

  const saveProgress = async (queryParams) => {
    const res = await axios.post('/save-progress', queryParams)
    console.log(res)
    return res
  }

  const trimVideo = async (queryParams, lesson_id) => {
    const res = await axios.post(`/trimmer/${lesson_id}`, queryParams)
    console.log(res)
    return res
  }

  const upload_record = async (queryParams, lesson_id) => {
    const res = await axios.post(`/lesson/${lesson_id}`, queryParams);
    return res.data;
  }

  const sendToVimeo = async (queryParams) => {
    const res = await axios.post('/send-to-vimeo', queryParams)
    console.log(res)
    return res
  }

  return {
    getCourse,
    getCourses,
    saveProgress,
    getQuestions,
    postQuestion,
    getNotes,
    postNotes,
    trimVideo,
    sendToVimeo,
    upload_record
  }
}
